@import '../../../styles/variables';
@import '../ScoringRequestDetails.module';

.info {
  position: absolute;
  right: 16px;
}

.helpModal {
  max-width: 750px !important;
}

.incomeTitle {
  font-weight: 700;
  font-size: 1.25rem;
}

.emptyRow {
  height: 30px;
}

.emptyCol {
  width: auto;
}

.incomeSourceCollapse {
  max-width: 24px;
  min-width: 24px;
}

.selectIncomeSourceCheckbox {
  width: 20px;
  height: 25px;
  cursor: pointer;
  display: inline-block;
}

.lineCheckboxLabelWrapper {
  label {
    cursor: pointer;
  }
}

.clickable {
  cursor: pointer;
}

.selectedCard {
  background-color: $light-background-color !important;
}

.stickyStatsBox {
  position: sticky;
  top: 1em;
  overflow-y: auto;
  max-height: calc(100vh - 1em);

  @media print {
    display: initial;
  }
}

.printable {
  @include printableCollapseWrapper();
}

.helpButton {
  padding-right: 6px !important;
  right: 0;
}

.chartSize {
  height: auto !important;
  width: 100% !important;
  aspect-ratio: 16 / 10 !important;
}

.legendFontSize {
  font-size: 0.8rem;
}

.legendDisabled {
  color: $dark-background-color;
}

.cursorPointer {
  cursor: pointer;
}
