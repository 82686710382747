@import '../../styles/variables';

.changePassword {
  padding-bottom: 0;
  margin-bottom: 0;

  label {
    margin-bottom: 0;
  }

  input {
    padding-top: $spacing-amount / 2;
    margin-bottom: $spacing-amount;
  }
}

.userAttribute {
  font-weight: bold;
}
